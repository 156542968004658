export {default as ProfileContext} from './ProfileContext';

interface Family {
    id: string,
    name: string
}

export interface Profile {
    id: string,
    families: Family[],
    role:string,
    "created_at": string,
    "modified_at": string,
    "email": string,
    "first_name": string,
    "last_name": string,
    "date_of_birth": string,
    "phone": string,
    "gender": string,
    "photo": string,
    "has_push_notifications": boolean,
    "has_hided_my_posts": boolean,
    "blocked": boolean,
    "is_staff": boolean,
    "regular_login": boolean,
    "unread_notifications": number,
    "last_activity": string,
    "post_count": number,
    "member_count": number,
    "child_count": number
}
