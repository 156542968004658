import React from "react";
import { Form, Input, Button, Alert } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { SessionApi } from "../api";
import { APIGenericError, APIValidationError, Request } from "../lib";

/**
 * Extended props from antd from components props for login form
 *
 * @interface LoginFormProps
 * @property {string} email - user email
 * @property {password} password - user password
 */
interface LoginFormProps extends FormComponentProps {
  email: string;
  password: string;
}

/**
 * Internal state for login form
 *
 * @interface LoginFormState
 * @property {*} error - Error represent instance of one of error classes
 * @property {boolean} loading - Request indicator(if true, request is in pending mode)
 */
interface LoginFormState {
  error: string | null;
  loading: false;
}

/**
 * Render login form
 * @example
 *
 *   <LoginForm />
 *
 */

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  constructor(props: LoginFormProps) {
    super(props);

    this.state = {
      loading: false,
      error: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const { form } = this.props;

    form.validateFields((err, fields) => {
      if (!err) {
        SessionApi.login(fields)
          .then(response => {
            this.setState({
              loading: false
            });
            Request.loggedInCb(response);
          })
          .catch(err => {
            let error = null;
            if (err instanceof APIGenericError) {
              error = err.getFieldsError();
            }

            if (err instanceof APIValidationError) {
              error = null;
              const errors = err.getFieldsError();

              form.setFields(errors);
            }

            this.setState({
              error,
              loading: false
            });
          });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {this.state.error ? (
          <Alert message={this.state.error} type="error" />
        ) : null}

        <Form.Item label="Email">
          {getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Please enter a valid email",
                type: "email"
              }
            ]
          })(<Input placeholder="Email" />)}
        </Form.Item>

        <Form.Item label="Password">
          {getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message: "Please input your Password"
              }
            ]
          })(<Input.Password placeholder="Password" />)}
        </Form.Item>

        <Form.Item>
          <Button
            loading={this.state.loading}
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedLoginForm = Form.create({ name: "login" })(LoginForm);
export default WrappedLoginForm;
