import { Request } from '../lib';

export default {
  retrieve: () => {
    return Request.get('/content/terms/retrieve');
  },
  update: (data: object) => {
    return Request.post('/content/terms/update', data);
  }
};
