/**
 * Class representing storage to manipulate data session data
 */

class SessionStorage {
  private accessTokenKey = 'access-token';
  private refreshTokenKey = 'refresh-token';

  /**
   * Return access token for session
   * @returns {string}
   */
  getAccessToken() {
    return localStorage.getItem(this.accessTokenKey);
  }

  /**
   * Set access token for session
   * @param {string} token String representation for jwt token.
   *
   * @returns {void}
   */
  setAccessToken(token: string) {
    return localStorage.setItem(this.accessTokenKey, token);
  }

  /**
   * Return refresh token for session
   * @returns {string}
   */
  getRefreshToken() {
    return localStorage.getItem(this.refreshTokenKey);
  }

  /**
   * Set refresh token for session
   * @param {string} token String representation for jwt token.
   *
   * @returns {void}
   */
  setRefreshToken(token: string) {
    return localStorage.setItem(this.refreshTokenKey, token);
  }

  /**
   * Delete refresh and access token
   * @returns {void}
   */
  clearStorage() {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
  }
}

export default new SessionStorage();
