import { Avatar, Dropdown, Icon, Layout, Menu, Spin, Modal } from "antd";
import React, { useContext, useState } from "react";
import "../assets/sass/header.scss";
import { Profile, ProfileContext } from "../profile";
import { Request } from "../lib";
import ResetPasswordModal from "../ResetPasswordForm";

const { Header } = Layout;

export interface HeaderLayoutProps {
  collapsed: boolean;
  setCollapsed(value: boolean): void;
}

/**
 * Render main dashboard header
 */
const HeaderLayout: React.FC<HeaderLayoutProps> = ({
  collapsed,
  setCollapsed,
}) => {
  const profile = useContext(ProfileContext);

  const [showModal, setShowModal] = useState<boolean>(false);
  const onLogout = () => {
    Request.loggedOutCb();
  };

  const menu = (
    <Menu>
      <Menu.Item key="reset-password" onClick={() => setShowModal(true)}>
        <Icon type="lock" />
        change password
      </Menu.Item>
      <Menu.Item key="logout" onClick={() => onLogout()}>
        <Icon type="logout" />
        logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Header className="header">
        <Icon
          className="trigger"
          type={collapsed ? "menu-unfold" : "menu-fold"}
          onClick={() => setCollapsed(!collapsed)}
        />
        <Dropdown overlay={menu} trigger={["click"]}>
          <span className="user">
            {profile?.data ? (
              <ProfileAvatar profile={profile.data} />
            ) : (
              <Spin size="small" className="spin" />
            )}
          </span>
        </Dropdown>
      </Header>
      <Modal
        title="Reset Password"
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
      >
        <ResetPasswordModal setShowModal={() => setShowModal(false)} />
      </Modal>
    </>
  );
};

export default HeaderLayout;

const ProfileAvatar = ({ profile }: { profile: Profile }) => (
  <>
    <Avatar className="user_avatar" src={profile.photo} alt="avatar" />
    <span>{profile.first_name}</span>
  </>
);
