import { Request } from '../lib';

export default {
  list: (data: object) => {
    return Request.get('/loyalty_points/products/', data);
  },
  buy: (data: object) => {
    return Request.post('/loyalty_points/buy_product', data);
  },
  get: (id: string) => {
    return Request.get(`/loyalty_points/products/${id}/`);
  },
  create: (data: object) => {
    return Request.post(`/loyalty_points/products/`, data);
  },
  update: (id: string, data: object) => {
    return Request.patch(`/loyalty_points/products/${id}/`, data);
  },
  remove: (id: number) => {
    return Request.delete(`/loyalty_points/products/${id}/`);
  }
};
