import { WrappedFormUtils } from 'antd/lib/form/Form';
import {$Object} from "./types";

/**
 * This sets fields errors, usually returned by the API.
 *
 * @example
 *
 * setFieldsError({ email: ["Invalid email"] }, props.form);
 *
 * @param {object} fieldsError - an object that holds fields with an array of strings
 * @param {WrappedFormUtils} form - the form props of the custom form component.
 */
export const setFieldsError = (
  fieldsError: any,
  form: WrappedFormUtils
): void => {
  const { getFieldsValue, setFields } = form;
  const currentField = getFieldsValue();
  const newFields: any = {};

  Object.keys(fieldsError).forEach(key => {
    newFields[key] = {
      value: currentField[key],
      errors: fieldsError[key].map((msg: string) => new Error(msg))
    };
  });

  setFields(newFields);
};

/**
 * This function checks if fields exists in object.
 * It returns `default value` when path was not found.
 *
 * @example
 *
 * has(myObject, "path.to.field.count", 0);
 *
 * @param {object} baseObj - an object that holds fields
 * @param {WrappedFormUtils} pathToAttr - the form props of the custom form component.
 * @param {D = T} defaultValue - default value that will be returned
 */
export function has<T, D = T>(
  baseObj: any,
  pathToAttr: string,
  defaultValue: D
): T | D {
  return (
    (typeof pathToAttr === 'string' &&
      pathToAttr
        .replace(/\[|\]\[|\]\./g, '.')
        .split('.')
        .reduce((prev, curr) => prev && prev[curr], baseObj)) ||
    defaultValue
  );
}

export const hasValues = (obj: $Object) =>
  Object.keys(obj).find(i => {
    const value = obj[i];

    return value !== null && value !== undefined && value !== '';
  });

  export const hasValidPhone = (str: string) =>
  /[a-zA-Z]/.test(str) ? " —" : str;

export const hasValidMail = (str: string) => (str?.includes("@") ? str : " —");
