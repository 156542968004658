import React from 'react';
import logo from '../assets/images/logo-transparent.png';
import '../assets/sass/session.scss';

/**
 * Render session layout(for route like login/register/reset password)
 * Usually, OtherComponent will be a related form
 * @example
 *
 *    <SessionLayout>
 *      <OtherComponent />
 *    </SessionLayout>
 *
 */
const SessionLayout: React.FC = ({ children }) => {
  return (
    <div className="login-layout">
      <div className="session-wrapper">
        <div className="site-logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="session-form">{children}</div>
      </div>
    </div>
  );
};

export default SessionLayout;
