import { Request } from '../lib';

export default {
  list: (data: object) => {
    return Request.get('/subscriptions/vouchers/', data)
  },
  byId: (id: number) => {
    return Request.get(`/subscriptions/vouchers/${id}/`)
  },
  update: (id: number, data: object) => {
    return Request.patch(`/subscriptions/vouchers/${id}/`, data)
  },
  create: (data: object) => {
    return Request.post(`/subscriptions/vouchers/`, data)
  },
  delete: (id: number) => {
    return Request.delete(`/subscriptions/vouchers/${id}/`)
  }
}
