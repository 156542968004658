import { Request } from '../lib';

export default {
  /**
   * Log in with data send from UI form
   * @param {Object} data - Data to be send to api
   */

  login: (data = {}) => {
    return Request.post('/users/login', data);
  }
};
