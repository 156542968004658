import { Request } from "../lib";

export default {
  list: (data: object) => {
    return Request.get("/users/dashboard/list_users", data);
  },
  byId: (id: string) => {
    return Request.get(`/users/dashboard/${id}`);
  },
  block: (data: object) => {
    return Request.post(`/users/dashboard/block_unblock`, data);
  },
  getStory: (data: object) => {
    return Request.get(`/appointments/dashboard/get_histories`, data);
  },
  getSubscriptions: (data: object) => {
    return Request.get(`/subscriptions/dashboard/user_subscriptions`, data);
  },
  getAppointments: (data: object) => {
    return Request.get(`/appointments/dashboard/get_appointments`, data);
  },
  changePassword: (data: object) => {
    return Request.post("/users/change-password", data);
  },
  restorePassword: (data: object) => {
    return Request.post("/users/restore-password", data);
  },
  isPayed: (data: object) => {
    return Request.post(
      "/subscriptions/dashboard/user_subscriptions/pay",
      data
    );
  },
  editSubscription: (data: object) => {
    return Request.put(
      "/subscriptions/dashboard/edit_user_subscriptions",
      data
    );
  },
  getValuablePoints: (data: object) => {
    return Request.get("/users/dashboard/valuablepoints/", data);
  },
  addUser: (data: object) => {
    return Request.post("/users/dashboard/import", data);
  },
};
