import { Request } from '../lib';

export default {
  list: (data: object) => {
    return Request.get('/salons/dashboard/get_salons', data)
  },
  byId: (id: number) => {
    return Request.get(`/salons/dashboard/salon/${id}`)
  },
  update: (id: number, data: object) => {
    return Request.post(`/salons/dashboard/salon/${id}`, data)
  },
  feedbacks: (data: object) => {
    return Request.get(`/feedbacks/dashboard/get_feedbacks`, data)
  },
  services: (data: object) => {
    return Request.get(`/services/dashboard/list`, data)
  },
  barbers: (data: object) => {
    return Request.get(`/salons/dashboard/get_hairdressers`, data)
  },
  images: (id: number, data: object) => {
    return Request.post(`/salons/dashboard/set_images/${id}`, data)

  }
}
