import { Request } from '../lib';

export default {
  list: (data: object) => {
    return Request.get('/users/dashboard/list_moderators', data)
  },
  byId: (id: number) => {
    return Request.get(`/users/dashboard/list_moderators`, { id: id })
  },
  create: (data: object) => {
    return Request.post(`/users/dashboard/moderators/`, data)
  },
  update: (id: number, data: object) => {
    return Request.patch(`/users/dashboard/moderators/${id}/`, data)
  },
  delete: (id: number) => {
    return Request.delete(`/users/dashboard/moderators/${id}/`)
  }
}
