import React from "react";
import Loadable from "react-loadable";
import { Loading } from "../loading-placeholder";

export interface Route {
  text: string | null;
  path: string;
  component?: React.ReactNode;
  icon?: string;
  access?: boolean;
  children: Route[];
}

export const routes: Route[] = [
  //users
  {
    path: "/users",
    text: "Users",
    icon: "user",
    access: true,
    component: Loadable({
      loader: () => import("../users/UsersPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  {
    path: "/users/add",
    text: null,
    icon: "user-add",
    access: false,
    component: Loadable({
      loader: () => import("../users/AddUserPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  {
    path: "/users/detail/:id",
    text: null,
    icon: "question-circle",
    access: false,
    component: Loadable({
      loader: () => import("../users/UsersDetailPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  //moderators
  {
    path: "/moderators",
    text: "Moderators",
    icon: "team",
    access: false,
    component: Loadable({
      loader: () => import("../moderators/ModeratorsPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  {
    path: "/moderators/add",
    text: null,
    icon: "user-add",
    access: false,
    component: Loadable({
      loader: () => import("../moderators/AddModeratorPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  {
    path: "/moderators/edit/:id",
    text: null,
    icon: "user-add",
    access: false,
    component: Loadable({
      loader: () => import("../moderators/EditModeratorPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  // Salons
  {
    path: "/shop",
    text: "Shop",
    icon: "shop",
    access: false,
    children: [
      {
        path: "/barbers",
        text: "Barbers",
        icon: "smile",
        access: false,
        component: Loadable({
          loader: () => import("../barbers/BarbersPage"),
          loading: Loading,
          delay: 300,
        }),
        children: [],
      },
      {
        path: "/barbers/detail/:id",
        text: "",
        icon: "smile",
        access: false,
        component: Loadable({
          loader: () => import("../barbers/BarbersDetailPage"),
          loading: Loading,
          delay: 300,
        }),
        children: [],
      },
      {
        path: "/salons",
        text: "Salons",
        icon: "home",
        access: false,
        component: Loadable({
          loader: () => import("../salons/SalonsPage"),
          loading: Loading,
          delay: 300,
        }),
        children: [],
      },
      {
        path: "/salons/edit/:id",
        text: "",
        icon: "home",
        access: false,
        component: Loadable({
          loader: () => import("../salons/EditSalonPage"),
          loading: Loading,
          delay: 300,
        }),
        children: [],
      },
      {
        path: "/salons/detail/:id",
        text: "",
        icon: "home",
        access: false,
        component: Loadable({
          loader: () => import("../salons/SalonsDetailPage"),
          loading: Loading,
          delay: 300,
        }),
        children: [],
      },
      {
        path: "/services",
        text: "Services",
        icon: "container",
        access: false,
        component: Loadable({
          loader: () => import("../services/ServicesPage"),
          loading: Loading,
          delay: 300,
        }),
        children: [],
      },
      {
        path: "/services/detail/:id",
        text: "",
        icon: "container",
        access: false,
        component: Loadable({
          loader: () => import("../services/ServicesDetailPage"),
          loading: Loading,
          delay: 300,
        }),
        children: [],
      },
    ],
  },

  // subscriptions
  {
    path: "/subscriptions",
    text: "Subscriptions",
    icon: "snippets",
    access: false,
    component: Loadable({
      loader: () => import("../subscriptions/SubscriptionsPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  {
    path: "/subscriptions/add",
    text: null,
    icon: "file-add",
    access: false,
    component: Loadable({
      loader: () => import("../subscriptions/AddSubscriptionPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  {
    path: "/subscriptions/edit/:id",
    text: null,
    icon: "file-add",
    access: false,
    component: Loadable({
      loader: () => import("../subscriptions/EditSubscriptionPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  {
    path: "/subscriptions/vouchers",
    text: null,
    icon: "file-add",
    access: false,
    component: Loadable({
      loader: () => import("../subscriptions/VouchersPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  {
    path: "/subscriptions/vouchers/:id",
    text: null,
    icon: "file-add",
    access: false,
    component: Loadable({
      loader: () => import("../subscriptions/VouchersPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  {
    path: "/subscriptions/voucher/add",
    text: null,
    icon: "file-add",
    access: false,
    component: Loadable({
      loader: () => import("../subscriptions/VoucherForm"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  {
    path: "/subscriptions/voucher/edit/:id",
    text: null,
    icon: "file-add",
    access: false,
    component: Loadable({
      loader: () => import("../subscriptions/VoucherForm"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  {
    path: "/points",
    text: "Points",
    icon: "fire",
    access: false,
    children: [
      {
        path: "/valuable",
        text: "Valuable Points",
        icon: "crown",
        access: false,
        component: Loadable({
          loader: () => import("../valuable/ValuablePoints"),
          loading: Loading,
          delay: 300,
        }),
        children: [],
      },
      {
        path: "/loyalty",
        text: "Loyalty Points",
        icon: "star",
        access: false,
        component: Loadable({
          loader: () => import("../loyalty/LoyaltyPoints"),
          loading: Loading,
          delay: 300,
        }),
        children: [],
      },
      {
        path: "/products",
        text: "Products",
        icon: "gift",
        access: false,
        component: Loadable({
          loader: () => import("../loyalty/Products"),
          loading: Loading,
          delay: 300,
        }),
        children: [],
      },
      {
        path: "/products/add",
        text: null,
        icon: "plus",
        access: false,
        component: Loadable({
          loader: () => import("../loyalty/ProductPage"),
          loading: Loading,
          delay: 300,
        }),
        children: [],
      },
      {
        path: "/products/edit/:id",
        text: null,
        icon: "edit",
        access: false,
        component: Loadable({
          loader: () => import("../loyalty/ProductPage"),
          loading: Loading,
          delay: 300,
        }),
        children: [],
      },
    ],
  },
  // 404
  {
    path: "/404",
    text: "",
    icon: "team",
    access: false,
    component: Loadable({
      loader: () => import("../404/NotFoundPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  // privacy
  {
    path: "/privacy",
    text: "Privacy",
    icon: "safety-certificate",
    access: false,
    component: Loadable({
      loader: () => import("../privacy/PrivacyPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
  // transactions
  {
    path: "/transactions",
    text: "Transactions",
    icon: "transaction",
    access: false,
    component: Loadable({
      loader: () => import("../transactions/TransactionsPage"),
      loading: Loading,
      delay: 300,
    }),
    children: [],
  },
];
