import React from 'react';
import { SessionLayout } from '../layout';
import "./ResetPassword.css"

/**
 * Render login page with session layout and login form
 * @example
 *
 *    <LoginPage />
 *
 */
const ResetPassword: React.FC = () => {
  return (
    <SessionLayout>
      <h1 className="reset-pass-warning">
          Please,<br />open the link from your email on mobile application in order to restore your password.
      </h1>
    </SessionLayout>
  );
};

export default ResetPassword;
