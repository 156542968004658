import React, { useState } from "react";

import { Layout } from "antd";
import HeaderLayout from "./header";
import SiderLayout from "./sider";

import "../assets/sass/default.scss";

const { Content } = Layout;

/**
 * Render main dashboard layout which contains sidebar with routes + content route based
 * @example
 *
 *    <DefaultLayout>
 *      <OtherComponent />
 *    </DefaultLayout>
 *
 */
const DefaultLayout: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <Layout>
      <SiderLayout collapsed={collapsed} />

      <Layout>
        <HeaderLayout collapsed={collapsed} setCollapsed={setCollapsed} />

        <Content className="main-content">{children}</Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
