import React from 'react';
import LoginForm from './LoginForm';
import { SessionLayout } from '../layout';

/**
 * Render login page with session layout and login form
 * @example
 *
 *    <LoginPage />
 *
 */
const LoginPage: React.FC = () => {
  return (
    <SessionLayout>
      <LoginForm />
    </SessionLayout>
  );
};

export default LoginPage;
