import { Request } from '../lib';

export default {
  list: (data: object) => {
    return Request.get('/salons/dashboard/get_hairdressers', data)
  },
  update: (id: number, data: object) => {
    return Request.post(`/salons/dashboard/hairdresser/${id}`, data)
  },
  feedbacks: (data: object) => {
    return Request.get(`/feedbacks/dashboard/get_feedbacks`, data)
  },
  deletFeedback: (id: number) => {
    return Request.delete(`/feedbacks/dashboard/delete_feedback/${id}`)
  }
}
