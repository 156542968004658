import { Request } from '../lib';

export default {
  loyalty: (data: object) => {
    return Request.get('/loyalty_points/loyalty-points-turnovers/', data);
  },
  patch: (id: number, data: object) => {
    return Request.patch(
      `/loyalty_points/loyalty-points-turnovers/${id}/`,
      data
    );
  },
  delete: (id: number) => {
    return Request.delete(`/loyalty_points/loyalty-points-turnovers/${id}/`);
  },
  valuable: (data: object) => {
    return Request.get('/users/dashboard/valuablepoints/', data);
  },
  getRatePoints: () => {
    return Request.get('/valuable-points/pointschangerateviewset/');
  },
  changeRatePoints: (id: number, data: object) => {
    return Request.patch(
      `/valuable-points/pointschangerateviewset/${id}/`,
      data
    );
  },
  createBulk: (data: object) => {
    return Request.post(`/loyalty_points/loyalty-points-turnovers/bulk/`, data);
  },
};
