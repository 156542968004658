import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  RouteComponentProps,
  matchPath
} from 'react-router-dom';
import { ProtectedRoute, routes } from '../router';
import { Login } from '../login';
import { ResetPassword } from '../reset-password';
import { ProfileContext } from '../profile';
import { ProfileApi } from "../api";
import { Request, SessionStorage } from '../lib';
import { Route as IRoute } from "../router/routes";
import { DefaultLayout } from '../layout';

/**
 * Render our app entry point depending on current route
 * @example
 *
 *    <App />
 *
 */
const App = ({ history }: RouteComponentProps) => {
  const [profile, setProfile] = useState();

  useEffect(() => {
    const accessToken = SessionStorage.getAccessToken();

    // TODO: refactor to remove duplicates in use effect below.
    if (accessToken) {
      const _fetchProfile = async () => {
        const profile = await ProfileApi.profile();
        setProfile(profile)
      };
      _fetchProfile();
    }
  }, []);

  useEffect(() => {
    Request.setSessionExpiredCb(() => {
      SessionStorage.clearStorage();
      history.push('/login');
    });

    Request.setLoggedIn(({ access, refresh }: any) => {
      SessionStorage.setAccessToken(access);
      SessionStorage.setRefreshToken(refresh);
      history.push('/users');

      const _fetchProfile = async () => {
        const profile = await ProfileApi.profile();
        setProfile(profile)
      };
      _fetchProfile();
    });

    Request.setLoggedOut(() => {
      SessionStorage.clearStorage();
      history.push('/login');
      setProfile(null)
    });

  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  return (
    <div className="app-main">
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/reset-password/:token" component={ResetPassword} />
        <Route exact path="/" render={() => <Redirect to="/users" />} />
        <ProfileContext.Provider value={{ data: profile }}>
          <DefaultLayout>
            {renderProtectedRoutesArray(routes)}
            <Route render={(match: any) => {
              let isMatch: boolean = false;
              let path = match.location.pathname;
              if (matchPath(path, { path: "/", exact: true, strict: true })) isMatch = true;
              routes.forEach(r => {
                if (matchPath(path, { path: r.path })) isMatch = true;
                r.children.forEach(child => {
                  if (matchPath(path, { path: child.path })) isMatch = true;
                });
              })
              if (!isMatch)
                return <Redirect to="/404" />
            }} />
          </DefaultLayout>
        </ProfileContext.Provider>
      </Switch>
    </div>
  );
};

export default withRouter(App);

const renderProtectedRoutesArray = (routes: Array<IRoute>): Array<JSX.Element> => {
  const arr: Array<JSX.Element> = [];
  routes.forEach(r => {
    if (!r.children.length) {
      arr.push(<ProtectedRoute
        exact
        key={r.path}
        path={r.path}
        component={r.component}
      />)
    } else {
      renderProtectedRoutesArray(r.children)
        .forEach(cr => arr.push(cr))
    }
  });
  return arr
};