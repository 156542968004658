import React from 'react';
import { LoadingComponentProps } from 'react-loadable';
import { Spin, Alert } from 'antd';

/**
 * Render loading placeholder for loadable component(depending on component loading state, can render spinner or error message)
 * @example
 *
 *    Loadable({
 *      loader: () => import('path to component'),
 *      loading: LoadingPlaceholder,
 *      delay: 300
 *    })
 *
 */
const LoadingPlaceholder = (props: LoadingComponentProps) => {
  if (props.error) {
    return <Alert message={props.error || 'Error!'} />;
  } else if (props.pastDelay) {
    return <Spin />;
  }

  return null;
};

export default LoadingPlaceholder;
