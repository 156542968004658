import { FormComponentProps } from "antd/lib/form";
import React, { useState } from "react";
import { Button, Form, Icon, Input, message } from "antd";
import { UsersApi } from "./api";
import { setFieldsError } from "./lib/utils";

export interface ResetPasswordModalProps extends FormComponentProps {
  setShowModal(): void
};

const ResetPasswordForm: React.FC<ResetPasswordModalProps> = ({ form, setShowModal }) => {
  const { getFieldDecorator } = form;
  const [confirmDirty, setConfirmDirty] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        UsersApi.changePassword(values)
          .then(() => {
            setShowModal()
          })
          .catch((e) => {
            if (e.apiError && e.apiError.error) {
              return setFieldsError(e.apiError.error, form);
            }
            message.error('Server error');
          })
      }
    });
  };

  const compareToFirstPassword = (rule: any, value: string, callback: any) => {
    if (value && value !== form.getFieldValue('new_password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule: any, value: string, callback: any) => {
    if (value && confirmDirty) {
      form.validateFields(['confirm_password'], { force: true });
    }
    callback();
  };

  const handleConfirmBlur = (e: any) => {
    const value = e.target.value;
    setConfirmDirty(confirmDirty || !!value);
  };

  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <Form.Item>
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: 'Please input old password!',
            },
            {
              min: 8,
              message: 'Please input at least 8 characters!',
            }
          ],
        })(
          <Input.Password
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Old password"
          />
          ,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('new_password', {
          rules: [
            {
              required: true,
              message: 'Please input new password!',
            },
            {
              min: 8,
              message: 'Please input at least 8 characters!',
            },
            {
              validator: validateToNextPassword,
            },
          ],
        })(
          <Input.Password
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="New password"
          />,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('confirm_password', {
          rules: [{
            required: true,
            message: 'Please confirm new password!',
          },
          {
            validator: compareToFirstPassword,
          },
          ],
        })(
          <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} onBlur={handleConfirmBlur}
            placeholder="Confirm new password" />,
        )}
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Form.create<ResetPasswordModalProps>()(ResetPasswordForm);