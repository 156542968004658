import fetch from 'cross-fetch';
import * as qs from 'query-string';

import { APIGenericError, APIValidationError, SessionStorage } from '../lib';
import { APIError } from './errors';

/**
 * Class representing wrapper for cross-fetch module
 */
class Request {
  private baseApiUrl: string | undefined = process.env.REACT_APP_API_URL;
  private isRefreshingToken: boolean = false;
  private pendingRequests: any = [];
  private sessionExpiredCb = () => { };
  loggedOutCb = () => { };
  loggedInCb = ({ access, refresh }: any) => { };

  /**
   * Prepare data to send and make a request to the api
   * @param {string} url - Request url is build from:
   *      1. Base API Url (ex.: http://example.com)
   *      3. Url of asset we request(ex.: /users/1, which represent user id)
   *    The request will be:  http://example.com/users/1
   * @param {object} data - Represent body object
   * @param {object} config - Config represent object which can override request options
   * @param {string} method - One of GET, POST, PUT, PATCH, DELETE
   *
   * @returns {Promise<any>}
   */
  private async makeRequest(
    url: string,
    data = {},
    config = {},
    method: string
  ) {
    let requestUrl = `${this.baseApiUrl}${url}`;
    const isBodyFormData = data instanceof FormData;

    const headers = this.getRequestHeaders(isBodyFormData);
    const requestOptions: RequestInit = {
      method,
      headers,
      ...config
    };  
    if (isBodyFormData) {
      // @ts-ignore
      requestOptions.body = data;
    } else if (method !== 'GET') {
      requestOptions.body = JSON.stringify(data);
    }

    try {
      const res = await fetch(requestUrl, requestOptions);
      let data;

      if (res.status === 204) {
        return;
      }

      data = await res.json();
      if (res.ok) {
        return data;
      } else {
        if (res.status === 401) {
          const retryOriginalRequest = new Promise(resolve => {
            this.publishPendingRequest(() => {
              requestOptions.headers = this.getRequestHeaders(isBodyFormData);
              resolve(
                fetch(requestUrl, requestOptions).then(res => res.json())
              );
            });
          });

          if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            await this.refreshToken();
            this.isRefreshingToken = false;
            this.consumePendingRequests();
          }

          return retryOriginalRequest;
        }

        const responseData: APIError = {
          error: data,
          status: res.status,
          statusText: res.statusText,
          url: res.url
        };
        throw responseData;
      }
    } catch (err) {
      let errorInstance = null;
      if (err.error && err.error.non_field_errors) {
        errorInstance = new APIGenericError(err);
      } else {
        errorInstance = new APIValidationError(err, requestOptions.body || {});
      }

      return Promise.reject(errorInstance);
    }
  }

  /**
   * Make a request to refresh token
   * @returns {Promise<any>}
   */
  private refreshToken() {
    return new Promise((resolve, reject) => {
      fetch(`${this.baseApiUrl}/users/refresh`, {
        method: 'POST',
        body: JSON.stringify({
          refresh: SessionStorage.getRefreshToken()
        }),
        headers: {
          'Content-type': 'application/json'
        }
      })
        .then(response => {
          if (response.status === 401) {
            this.sessionExpiredCb();
            return reject(response);
          }
          return response.json();
        })
        .then(({ access }) => {
          SessionStorage.setAccessToken(access);
          resolve();
        });
    });
  }

  /**
   * Get headers for request
   * @param {boolean} isFormData If is form data, then content type is not application/json. Param is used when we work with files for example
   * @returns {Object}
   */

  private getRequestHeaders(isFormData = false) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${SessionStorage.getAccessToken()}`
    };

    if (isFormData) {
      delete headers['Content-Type'];
    }

    return headers;
  }

  /**
   * Resolve pending requests with new access-token
   * @returns {void}
   */
  private consumePendingRequests() {
    this.pendingRequests.forEach((callback: any) => callback());
    this.clearPendingRequestsQueue();
  }

  /**
   * Put request in a queue that will be resolved after new access token is received
   * @param {function} callback Put callback function to pending requests queue
   * @returns {void}
   */
  private publishPendingRequest(callback: any) {
    this.pendingRequests.push(callback);
  }
 
  /**
   * Clear pending requests
   * @returns {void}
   */
  clearPendingRequestsQueue() {
    this.pendingRequests = [];
  }

  /**
   * Make a GET request
   * @param {string} url - Path to asset we need (ex.: /1 - 1 represents id of the module we request)
   * @param {object} params - Represent query params which should be stringified
   * @param {object} config - Config represent object which can override request options
   *
   * @returns {Promise<any>}
   */
  get(url: string, params = {}, config = {}) {
    return this.makeRequest(
      `${url}?${qs.stringify(params)}`,
      params,
      config,
      'GET'
    );
  }

  /**
   * Make a POST request
   * @param {string} url - Path to asset we need
   * @param {object} data - Represent body object or FormData in case of files
   * @param {object} config - Config represent object which can override request options
   *
   * @returns {Promise<any>}
   */
  post(url: string, data = {}, config = {}) {
    return this.makeRequest(url, data, config, 'POST');
  }

  /**
   * Make a PUT request
   * @param {string} url - Path to asset we need
   * @param {object} data - Represent body object or FormData in case of files
   * @param {object} config - Config represent object which can override request options
   *
   * @returns {Promise<any>}
   */
  put(url: string, data = {}, config = {}) {
    return this.makeRequest(url, data, config, 'PUT');
  }

  /**
   * Make a DELETE request
   * @param {string} url - Path to asset we need
   * @param {object} data - Represent body object or FormData in case of files
   * @param {object} config - Config represent object which can override request options
   *
   * @returns {Promise<any>}
   */
  delete(url: string, data = {}, config = {}) {
    return this.makeRequest(url, data, config, 'DELETE');
  }

  /**
   * Make a PATCH request
   * @param {string} url - Path to asset we need
   * @param {object} data - Represent body object or FormData in case of files
   * @param {object} config - Config represent object which can override request options
   *
   * @returns {Promise<any>}
   */
  patch(url: string, data = {}, config = {}) {
    return this.makeRequest(url, data, config, 'PATCH');
  }

  /**
   * Set callback to execute when session expired
   * @param {function} cb - Callback function
   *
   * @returns {void}
   */
  setSessionExpiredCb(cb: any) {
    this.sessionExpiredCb = cb;
  }

  /**
   * Set callback to execute when user logged in
   * @param {function} cb - Callback function
   *
   * @returns {void}
   */
  setLoggedIn(cb: any) {
    this.loggedInCb = cb;
  }

  /**
   * Set callback to execute when user logged out
   * @param {function} cb - Callback function
   *
   * @returns {void}
   */
  setLoggedOut(cb: any) {
    this.loggedOutCb = cb;
  }
}

export default new Request();
