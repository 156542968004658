import { Request } from '../lib';

export default {
  list: (data: object) => {
    return Request.get('/services/dashboard/list', data)
  },
  byId: (id: number) => {
    return Request.get(`/services/dashboard/get_profile/${id}`)
  },
  update: (id: number, data: object) => {
    return Request.put(`/services/dashboard/${id}`, data)
  },
  barbers: (data: object) => {
    return Request.get(`/salons/dashboard/get_hairdressers`, data)
  }
}
