import { Icon, Layout, Menu } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ProfileContext } from "../profile";
import "../assets/sass/sider.scss";
import logo from "../assets/images/logo-transparent.png";
import { routes } from "../router";
import { Route } from "../router/routes";

const { Sider } = Layout;
const { SubMenu } = Menu;

interface SiderLayoutProps extends RouteComponentProps {
  collapsed: boolean;
}

/**
 * Render main dashboard side menu
 */
const SiderLayout: React.FC<SiderLayoutProps> = (props) => {
  const profile = useContext(ProfileContext);

  const [parent, setParent] = useState<string>("");

  let path = props.location.pathname;
  const pathItems = path.split("/");
  let parents: string = "";
  let tabs: string[] = [];

  const activateMenu = () => {
    parents = `/${pathItems[1]}`;
    setParent(parents);
    routes.forEach((r) => {
      if (r.children.length) {
        r.children.forEach((children) => {
          if (children.path === parents) {
            tabs.push(r.path);
          }
        });
      }
    });
  };

  useEffect(
    () => activateMenu(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path]
  );

  return (
    <Sider trigger={null} collapsible collapsed={props.collapsed} width={250}>
      <div className="site-logo">
        <img src={logo} alt="Logo" />
      </div>
      <Menu mode="inline" selectedKeys={[parent]} defaultOpenKeys={tabs}>
        {profile?.data && renderMenuItems(routes, profile.data.role)}
      </Menu>
    </Sider>
  );
};

export default withRouter(SiderLayout);

const renderMenuItems = (
  routes: Array<Route>,
  role: string
): Array<JSX.Element> => {
  const arr: Array<JSX.Element> = [];

  routes.forEach((r) => {
    if (!r.children.length) {
      if (r.text && (role === "ADMIN" || r.access)) {
        arr.push(
          <Menu.Item key={r.path}>
            <Link to={r.path}>
              <Icon type={r.icon} />
              <span>{r.text}</span>
            </Link>
          </Menu.Item>
        );
      }
    } else {
      if (role === "ADMIN" || r.access) {
        const sub = (
          <SubMenu
            key={r.path}
            title={
              <span>
                <Icon type={r.icon} /> <span>{r.text}</span>
              </span>
            }
          >
            {renderMenuItems(r.children, role)}
          </SubMenu>
        );
        arr.push(sub);
      }
    }
  });

  return arr;
};
